<template>
    <div class="main">
        <div style="margin:10px 0;">
            <van-search
                v-model="reqData.key"
                show-action
                shape="round"
                placeholder="请输入关键字"
                @search="onSearch"
                @cancel="onCancel"
            >
            </van-search>
            
        </div>
        <div style="height:85vh">
            <van-tree-select
                v-model:main-active-index="items.bm"
                height="100%"
                :items="items"
                @click-nav="leftNav"
                >
                <template #content>
                    <van-cell-group v-for="(item,index) in youData" :key="index">
                        <van-cell :title="item.title" is-link  @click="rightCik(item)"/>
                    </van-cell-group>
                </template>
            </van-tree-select>
        </div>
    </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted} from 'vue'
import loginRequest from '../../api/login'
export default {
    name: "guidance",
    setup() {
        const { proxy } = getCurrentInstance();
        const reqData = ref({
            key:"",
            bm:""
        });
        const onSearch = ()=> {
            ZaiReq();
        };
        const onCancel = ()=> {
            proxy.$root.$router.go(-1);
        };
        const activeIndex = ref(0);
        const items= ref([]);
        const youData= ref([]);
        const listReq = ()=> {
            loginRequest.znzy_list(reqData.value).then(res=>{
                if (res.returnCode == "00"){
                    // Toast(res.returnMessage);
                    console.log(res.rows);
                    // items.value=res.rows;
                    for(var i=0;i<res.rows.length;i++){
                        let obj={};
                        obj.text = res.rows[i].title;
                        obj.bm = res.rows[i].bm;
                        items.value.push(obj);
                    }
                    console.log(items);
                    reqData.value.bm=items.value[0].bm; //默认加载左侧列表第一项
                    ZaiReq();

                }
                
            });
        };
        const ZaiReq = ()=> {
            loginRequest.znzy_list(reqData.value).then(res=>{
                if (res.returnCode == "00"){
                    // Toast(res.returnMessage);
                    console.log(res.rows);
                    youData.value=res.rows;
                }
                
            });
        };
        const leftNav = (idx)=> {
            console.log(idx);
            let dqid = items.value[idx];
            console.log(dqid);
            reqData.value.bm =dqid.bm;
            console.log(reqData);
            ZaiReq();
        };
        const rightCik = (dqxx)=> {
            console.log(dqxx);
            proxy.$root.$router.push({name: "znzyList", params:dqxx});
        };
        onMounted(()=>{
            listReq();
        });

        return{
            reqData,
            onCancel,
            onSearch,
            activeIndex,
            items,
            listReq,
            leftNav,
            youData,
            rightCik
        }
    }
}
</script>

<style scoped>
    .main {
        /* margin: 0; */
        padding: 10px;
        min-height: 100vh;
    }

</style>